<template>
  <div class="row mx-0 my-5 justify-content-center">
    <div class="col-11 col-sm-9 col-md-6 col-lg-5 col-xl-4 col-xxl-3 my-4">
      <img src="@/assets/images/daily-sadaqah-logo.png" class="img-fluid" />
    </div>
  </div>
  <div class="row mx-0 justify-content-center points">
    <div class="col-md-10 col-lg-8">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <ul>
            <li>
              Help change lives daily
            </li>
            <li>
              Earn great rewards everyday
            </li>
            <li>
              Sadaqah is a barrier against Calamities
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <ul>
            <li>
              Be part of thousands of donors to support a huge impact together every month
            </li>
            <li>
              Support a new appeal every month
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Daily Sadaqah Info'
}
</script>

<style scoped>
.points {
  color: var( --green-color-dark );
  font-family: "quicksand", Sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}
.points li {
  margin-bottom: 1.5rem;
}
</style>
